import React, { useState, useEffect, useRef } from "react";
import "./InfoSection.css";
import backgroundImage from "../../../../assets/images/Elon_Trump_handshake.png";
import dmagaLogo from "../../../../assets/images/DMAGA icon.png";
import copyIcon from "../../../../assets/images/Copy_icon_small.png";
import caIcon from "../../../../assets/images/CA icon_small.png";

const InfoSection: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const priceChartRef = useRef<HTMLDivElement>(null);
  const tickerRef = useRef<HTMLDivElement>(null);
  

  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      "7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump"
    );
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  useEffect(() => {
    // Create and append CoinGecko widget scripts
    const loadWidgets = () => {
      const script1 = document.createElement("script");
      script1.src =
        "https://widgets.coingecko.com/gecko-coin-price-chart-widget.js";
      script1.async = true;

      const script2 = document.createElement("script");
      script2.src = "https://widgets.coingecko.com/gecko-coin-ticker-widget.js";
      script2.async = true;

      // Inject the price chart widget
      if (priceChartRef.current) {
        priceChartRef.current.innerHTML = `
           <gecko-coin-price-chart-widget locale="en" dark-mode="true" 
           outlined="true" 
           coin-id="dark-maga" 
           initial-currency="usd" 
           >
           </gecko-coin-price-chart-widget>
        `;
      }

      // Inject the ticker widget
      if (tickerRef.current) {
        tickerRef.current.innerHTML = `
          <gecko-coin-ticker-widget
            locale="en"
            dark-mode="true"
            outlined="true"
            coin-id="dark-maga"
            initial-currency="usd"
            >
          </gecko-coin-ticker-widget>
        `;
      }

      // Append the script tags to the document body
      document.body.appendChild(script1);
      document.body.appendChild(script2);

      return () => {
        // Cleanup the scripts when component unmounts
        document.body.removeChild(script1);
        document.body.removeChild(script2);
      };
    };

    loadWidgets();
  }, []);

  return (
    <section
      className="info-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="buy-subsection">
        <div className="buy-row-1">
          <img src={dmagaLogo} alt="dmaga-icon" />
        </div>
        <div className="buy-row-2">
          <div className="info-contract-address">
            <img src={caIcon} alt="CA" className="info-ca-image" />
            <p className="info-mint-address-text">7D7BRcBYepfi...</p>
            <button className="info-copy-button" onClick={handleCopyClick}>
              <img src={copyIcon} alt="Copy" className="info-copy-image" />
            </button>
            {copied && <span className="info-copied-text">Copied!</span>}
          </div>
        </div>
        <div className="buy-row-3">
          <h1>$DMAGA</h1>
        </div>
        <div className="info-content">
          <div className="widget-subsection">
            <div className="price-chart-widget" ref={priceChartRef}></div>
            <div className="price-ticker-widget" ref={tickerRef}></div>
          </div>
          <div className="info-content-right">
            <div className="buy-row-5">
              <a href="#guide-section" className="info-buy-button">
                BUY $DMAGA ON MOONSHOT
              </a>
            </div>
            <div className="buy-row-6">
              <div className="or-container">
                <span className="line"></span>
                <h1>OR</h1>
                <span className="line"></span>
              </div>
            </div>

            <div className="buy-row-7">
              <a
                href="https://jup.ag/swap/SOL-DMAGA"
                rel="noreferrer"
                target="_blank"
              >
                BUY ON JUPITER
              </a>
              <a
                href="https://raydium.io/swap/?inputMint=sol&outputMint=7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump"
                rel="noreferrer"
                target="_blank"
              >
                BUY ON RAYDIUM
              </a>
            </div>
          </div>
        </div>

        {/* New section for the widgets */}
      </div>
    </section>
  );
};

export default InfoSection;
