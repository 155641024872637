import React from "react";
import "./Navbar.css";
import xLogo from "../../../assets/images/X_logo_small.png";
import tgLogo from "../../../assets/images/Telegram_logo_small.png";

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li>
          <a href="/">HOME</a>
        </li>
        <li>
          <a
            href="https://dexscreener.com/solana/crswxnff21yhdxdybvwwcwq5ni2a4quk3qgyis5k7x7k"
            rel="noreferrer"
            target="_blank"
          >
            DEX
          </a>
        </li>
        <li>
          <a
            href="https://x.com/DarkMagaCoin"
            rel="noreferrer"
            target="_blank"
            className="navbar-socials"
          >
            <img src={xLogo} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://t.me/DMAGACoin"
            rel="noreferrer"
            target="_blank"
            className="navbar-socials"
          >
            <img src={tgLogo} alt="Telegram" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
