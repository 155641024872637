import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

interface VideoPlayerProps {
  videoUrl: string;
  previewImage: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, previewImage }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const playVideo = () => {
    setIsPlaying(true);
  };

  return (
    <div className="video-container">
      {isPlaying ? (
        <ReactPlayer
          url={videoUrl}
          playing
          controls
          width="100%"
          height="100%"
          className="react-player"
        />
      ) : (
        <div
          className="video-overlay"
          onClick={playVideo}
          style={{ backgroundImage: `url(${previewImage})` }}
        >
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
