import React, { useState } from "react";
import "./DescriptionSection.css";
import backgroundImage from "../../../../assets/images/Trump_laser_eye_3.png";

const DescriptionSection: React.FC = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <section
      className="description-section"
      id="description-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="description-title-row">
        <h1>
          darkmaga <span>rising</span>
        </h1>
      </div>
      <div className="description-text-row">
        <p>
          DMAGA (Dark MAGA) is a Solana-based cryptocurrency designed to capture
          the spirit of the Dark MAGA (Make America Great Again) movement. DMAGA
          emerged amid rising political tensions in the United States, serving
          as a digital rallying point for supporters of President Donald Trump.
          Donald Trump, the 45th President of the United States, has garnered
          passionate support. Even after leaving office, his influence continues
          to loom over the political landscape, spawning movements like "Dark
          MAGA," characterized by an aggressive martial stance.
        </p>
        
        {/* Additional paragraphs are shown/hidden based on the state */}
        {showMore && (
          <>
            <p>
              DMAGA is a digital manifestation of this ethos. Launched on the Solana
              blockchain for its performance and low transaction costs, Solana's
              scalability and speed make it ideal for decentralized applications and
              assets, including political tokens like DMAGA. The coin's purpose goes
              beyond financial transactions, serving as a symbol of allegiance and a
              means of fundraising for Dark MAGA causes.
            </p>
            <p>
              A pivotal moment for DMAGA was the assassination attempt on July 13,
              2024, when a highly publicized attempt on Donald Trump's life shocked
              the nation. This incident galvanized the Dark MAGA movement,
              intensifying their resolve and embedding their beliefs in the
              cryptocurrency. DMAGA represents more than just a cryptocurrency; it
              is a digital emblem of a politically charged movement.
            </p>
            <p>
              Rooted in Trump's ethos and catalyzed by dramatic events, DMAGA serves
              as a conduit for political expression, financial interaction, and
              ideological solidarity within the Dark MAGA community.
            </p>
          </>
        )}
      </div>

      {/* Button to toggle the additional paragraphs */}
      <div className="description-link-row">
        <button onClick={handleToggleShowMore}>
          {showMore ? "SHOW LESS" : "READ MORE"}
        </button>
      </div>
    </section>
  );
};

export default DescriptionSection;
