import React from "react";
import "./DmagafySection.css";
import muskIcon from "../../../../assets/images/1.jpeg";
import trumpIcon1 from "../../../../assets/images/2.jpeg";
import gigaIcon from "../../../../assets/images/3.jpeg";
import trumpIcon2 from "../../../../assets/images/4.jpeg";
import backgroundImage from "../../../../assets/images/trump.png";

const DmagafySection: React.FC = () => {

  return (
    <section className="dmagafy-section" id="dmagafy-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="dmagafy-row-1"></div>
      <div className="dmagafy-row-2">
        <img src={muskIcon} alt="Musk" />
        <img src={trumpIcon1} alt="Trump1" />
        <img src={gigaIcon} alt="Giga" />
        <img src={trumpIcon2} alt="Trump2" />
      </div>
      <div className="dmagafy-row-3">
        <h1>
          <span>DARKMAGA</span> PFP
        </h1>
        <p className="call-to-action">CREATE YOUR OWN!</p>
      </div>
      <div className="dmagafy-row-4">
        <p>Join the Dark Patriots and <span>DMAGAfy</span> any picture.</p>
      </div>
      <div className="dmagafy-row-5">
        <p>
        Simply upload and edit, download and share!
        </p>
      </div>
      <div className="dmagafy-row-6">
        <a
          href="https://dmagafy.netlify.app/"
          rel="noreferrer"
          target="_blank"
          className="dmagafy-button"
        >
          Get your DMAGA pfp and pictures
        </a>
      </div>
    </section>
  );
};

export default DmagafySection;
