import React from "react";
import "./PresentationSection.css";
import elonLogo from "../../../../assets/images/Elon icon.png";
import trumpLogo from "../../../../assets/images/Trump icon.png";
import backgroundImage from "../../../../assets/images/Trump_laser_eye_2.png";
import VideoPlayer from "../../../../components/common/VideoPlayer/VideoPlayer";
import elonThumbnail from "../../../../assets/images/elonPreview.png";
import trumpThumbnail from "../../../../assets/images/trumpPreview2.png";

const PresentationSection: React.FC = () => {
  return (
    <section
      className="presentation-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="right-column">
        <img src={trumpLogo} alt="Trump" />
        <p>
          ~ <span className="dark-maga-span">dark maga</span> is the next big
          thing ~
        </p>
        <div className="spacer"></div>
        <VideoPlayer
          videoUrl="https://youtu.be/8eYmUlFikWI?t=8947"
          previewImage={trumpThumbnail}
        />
      </div>
      <div className="left-column">
        <img src={elonLogo} alt="Elon" />
        <p>
          ~ i'm not just <span className="maga-span">maga</span> i'm{" "}
          <span className="dark-maga-span">dark maga</span> ~
        </p>
        <div className="spacer"></div>
        <VideoPlayer
          videoUrl="https://www.youtube.com/embed/lvnY0Na0gJA?si=kgeu-JaVJ0jV9p-L"
          previewImage={elonThumbnail}
        />
      </div>
    </section>
  );
};

export default PresentationSection;
