import React from "react";
import "./PostsSection.css";
import backgroundImage from "../../../../assets/images/3304df531d8a99a3b593c76c31381ed0.png";
import xLogo from "../../../../assets/images/X logo _ red.png";
import TwitterTimeline from "../../../../components/utils/TwitterTimeline/TwitterTimeline";
import screenshot1 from "../../../../assets/images/dmagaSS1.png";
import screenshot2 from "../../../../assets/images/dmagaSS2.png";
import screenshot3 from "../../../../assets/images/dmagaSS3.png";
import screenshot4 from "../../../../assets/images/dmagaSS4.png";
import screenshot5 from "../../../../assets/images/dmagaSS5.png";
import screenshot6 from "../../../../assets/images/dmagaSS6.png";
import screenshot7 from "../../../../assets/images/dmagaSS7.png";
import screenshot8 from "../../../../assets/images/dmagaSS8.png";
import screenshot9 from "../../../../assets/images/dmagaSS9.png";
import screenshot10 from "../../../../assets/images/dmagaSS10.png";

const screenshots = [
  { src: screenshot1, alt: "Screenshot1", link: "https://x.com/DarkMagaCoin/status/1842790031007920519" },
  { src: screenshot2, alt: "Screenshot2", link: "https://x.com/DarkMagaCoin/status/1846869374252065063" },
  { src: screenshot3, alt: "Screenshot3", link: "https://x.com/DarkMagaCoin/status/1846346057854394693" },
  { src: screenshot4, alt: "Screenshot4", link: "https://x.com/DarkMagaCoin/status/1846708038864134486" },
  { src: screenshot5, alt: "Screenshot5", link: "https://x.com/DarkMagaCoin/status/1844867948051394675" },
  { src: screenshot6, alt: "Screenshot6", link: "https://x.com/DarkMagaCoin/status/1845531523996684598" },
  { src: screenshot7, alt: "Screenshot7", link: "https://x.com/DarkMagaCoin/status/1846346057854394693" },
  { src: screenshot8, alt: "Screenshot8", link: "https://x.com/DarkMagaCoin/status/1845481799277060327" },
  { src: screenshot9, alt: "Screenshot9", link: "https://x.com/DarkMagaCoin/status/1844799143014703406" },
  { src: screenshot10, alt: "Screenshot10", link: "https://x.com/alx/status/1846600871863320650" },
];

const PostsSection: React.FC = () => {
  return (
    <section
      className="posts-section"
      id="posts-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="posts-title-row">
        <h1>$DMAGA on </h1>
        <a href="https://x.com/DarkMagaCoin" rel="noreferrer" target="_blank">
          <img src={xLogo} alt="Twitter" />
        </a>
      </div>
      <TwitterTimeline />
      <div className="posts-screenshots-row">
        <div className="posts-screenshots-wrapper">
          <div className="posts-slider">
            {[...screenshots, ...screenshots].map((screenshot, index) => (
              <a
                key={index}
                href={screenshot.link}
                rel="noreferrer"
                target="_blank"
                className="post-screenshot"
              >
                <img src={screenshot.src} alt={screenshot.alt} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <a
        href="https://x.com/DarkMagaCoin"
        rel="noreferrer"
        target="_blank"
        className="posts-link"
      >
        See more posts
      </a>
    </section>
  );
};

export default PostsSection;
