import React, { useState } from "react";
import './Footer.css';
import caIcon from "../../../assets/images/CA icon_small.png";
import copyIcon from "../../../assets/images/Copy_icon_small.png";
import xLogo from "../../../assets/images/X_logo_small.png";
import tgLogo from "../../../assets/images/Telegram_logo_small.png";

const Footer: React.FC = () => {
    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(
        "7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump"
      );
      setCopied(true);
  
      setTimeout(() => {
        setCopied(false); // Hide the copied message after 2 seconds
      }, 5000);
    };

    return(
        <footer className="footer">
            <div className="footer-column mint-address">
                <img src={caIcon} alt="CA" />
                <p className="mint-address-text">7D7BRcBYepfi...</p>
                <button className="footer-copy-button" onClick={handleCopyClick}><img src={copyIcon} alt="Copy" /></button>
                {copied && <span className="footer-copied-text">Copied!</span>}
            </div>
            <div className="footer-column links-column">
                <p className="footer-meta">DARK MAGA</p>
            </div>
            <div className="footer-column social-column">
                <a href="https://x.com/DarkMagaCoin" rel="noreferrer" target="_blank" className="footer-links"><img src={xLogo} alt="X" /></a>
                <a href="https://t.me/DMAGACoin" rel="noreferrer" target="_blank" className="footer-links"><img src={tgLogo} alt="Telegram" /></a>
            </div>
        </footer>
    );
};

export default Footer;
