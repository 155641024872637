import React from "react";
import "./HomePage.css";
import Navbar from "../../components/common/Navbar/Navbar";
import Footer from "../../components/common/Footer/Footer";
import HeroSection from "./sections/HeroSection/HeroSection";
import InfoSection from "./sections/InfoSection/InfoSection";
import PresentationSection from "./sections/PresentationSection/PresentationSection";
import GuideSection from "./sections/GuideSection/GuideSection";
import DescriptionSection from "./sections/DescriptionSection/DescriptionSection";
import PostsSection from "./sections/PostsSection/PostsSection";
import DmagafySection from "./sections/DmagafySection/DmagafySection";

const HomePage: React.FC = () => {
  return (
    <>
      <div className="home-page">
        <Navbar />

        <HeroSection />
        <InfoSection />
        <DmagafySection />
        <PresentationSection />
        <GuideSection />
        <DescriptionSection />
        <PostsSection />
        
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
