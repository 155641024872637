import React, { useState } from "react";
import "./GuideSection.css";
import backgroundImage from "../../../../assets/images/Trump_laser_eye_1.png";
import moonshotLogo from "../../../../assets/images/Moonshot_icon.png";
import raydiumLogo from "../../../../assets/images/raydium-icon.png";
import jupyterLogo from "../../../../assets/images/jupiter-ag-jup-logo.png";
import copyIcon from "../../../../assets/images/Copy_icon_small.png";
import iphone from "../../../../assets/images/iphone.png";
import raydiumGuide from "../../../../assets/images/Radiuym.png";
import jupyterGuide from "../../../../assets/images/Jupiter.png";

const GuideSection: React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<string>("MOONSHOT"); // Track which button is selected
  const [copied, setCopied] = useState(false);

  // Images
  const phoneVideo1 = "https://www.dropbox.com/scl/fi/kcjsgfr1ndu8xxqarr1om/finalaa.mp4?rlkey=deg6twvu9ba9137x2fwuv7r4x&st=ajdhnd8e&dl=1";

  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      "7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump"
    );
    setCopied(true);

    setTimeout(() => {
      setCopied(false); // Hide the copied message after 2 seconds
    }, 5000);
  };

  const handleButtonClick = (button: string) => {
    setSelectedButton(button);
  };

  return (
    <section
      className="guide-section"
      id="guide-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Title Row */}
      <div className="title-row">
        <h1>BUYERS GUIDES</h1>
      </div>

      {/* Buttons Row */}
      <div className="buttons-row">
        <button
          className={
            selectedButton === "MOONSHOT"
              ? "guide-button-selected"
              : "guide-button"
          }
          onClick={() => handleButtonClick("MOONSHOT")}
        >
          MOONSHOT
        </button>
        <button
          className={
            selectedButton === "RAYDIUM"
              ? "guide-button-selected"
              : "guide-button"
          }
          onClick={() => handleButtonClick("RAYDIUM")}
        >
          RAYDIUM
        </button>
        <button
          className={
            selectedButton === "JUPITER"
              ? "guide-button-selected"
              : "guide-button"
          }
          onClick={() => handleButtonClick("JUPITER")}
        >
          JUPITER
        </button>
      </div>

      {/* Guide Content and Phone Image */}
      <div className="guide-content">
        <div className="guide-left">
          {selectedButton === "MOONSHOT" && (
            <div className="moonshot-guide">
              <div className="guide-title">
                <h1>HOW TO BUY $DMAGA ON</h1>
                <img src={moonshotLogo} alt="Moonshot" className="guide-logo" />
                <h1>MOONSHOT</h1>
              </div>
              <p className="step-1">
                <span className="step">STEP 1: </span>
                <span className="step-content">
                  DOWNLOAD THE MOONSHOT APP OR GO TO THEIR WEBSITE
                </span>
              </p>
              <p className="step-2">
                <span className="step">STEP 2: </span>
                <span className="step-content">
                  TOP UP YOUR ACCOUNT (4 DIFFERENT METHODS)
                </span>
              </p>
              <p className="step-3">
                <span className="step">STEP 3: </span>
                <span className="step-content">
                  SEARCH FOR DARK MAGA ($DMAGA)
                </span>
                <button className="copy-button" onClick={handleCopyClick}>
                  <img src={copyIcon} alt="Copy" />
                </button>
                {copied && <span className="copied-text">Copied!</span>}
              </p>
              <p className="step-4">
                <span className="step">STEP 4: </span>
                <span className="step-content">
                  PURCHASE WITH ONE CLICK FROM YOUR DEPOSITED FUNDS
                </span>
              </p>
              <a
                className="guide-buy-button"
                href="https://moonshot.money/"
                rel="noreferrer"
                target="_blank"
              >
                BUY $DMAGA ON MOONSHOT
              </a>
            </div>
          )}
          {selectedButton === "RAYDIUM" && (
            <div className="raydium-guide">
              <div className="guide-title">
                <h1>HOW TO BUY $DMAGA ON</h1>
                <img src={raydiumLogo} alt="Raydium" className="guide-logo" />
                <h1>RAYDIUM</h1>
              </div>
              <p className="step-1">
                <span className="step">STEP 1: </span>
                <span className="step-content">
                  GO TO RAYDIUM WEBSITE AND PREPARE YOUR WALLET
                </span>
              </p>
              <p className="step-2">
                <span className="step">STEP 2: </span>
                <span className="step-content">
                  CONNECT YOUR WALLET (MOST WALLETS ALLOWED)
                </span>
              </p>
              <p className="step-3">
                <span className="step">STEP 3: </span>
                <span className="step-content">
                  SEARCH FOR DARK MAGA ($DMAGA)
                </span>
                <button className="copy-button" onClick={handleCopyClick}>
                  <img src={copyIcon} alt="Copy" />
                </button>
                {copied && <span className="copied-text">Copied!</span>}
              </p>
              <p className="step-4">
                <span className="step">STEP 4: </span>
                <span className="step-content">
                  PURCHASE FROM YOUR CONNECTED WALLET
                </span>
              </p>
              <a
                className="guide-buy-button"
                href="https://raydium.io/swap/?inputMint=sol&outputMint=7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump"
                rel="noreferrer"
                target="_blank"
              >
                BUY $DMAGA ON RAYDIUM
              </a>
            </div>
          )}
          {selectedButton === "JUPITER" && (
            <div className="jupyter-guide">
              <div className="guide-title">
                <h1>HOW TO BUY $DMAGA ON</h1>
                <img src={jupyterLogo} alt="Jupyter" className="guide-logo" />
                <h1>JUPITER</h1>
              </div>
              <p className="step-1">
                <span className="step">STEP 1: </span>
                <span className="step-content">
                  GO TO JUPITER WEBSITE: <a className="jupyter-link" href="https://jup.ag/swap/SOL-DMAGA" rel="noreferrer" target="blank">jup.ag</a>
                </span>
              </p>
              <p className="step-2">
                <span className="step">STEP 2: </span>
                <span className="step-content">
                  CONNECT YOUR WALLET (MOST WALLETS ALLOWED)
                </span>
              </p>
              <p className="step-3">
                <span className="step">STEP 3: </span>
                <span className="step-content">
                  SEARCH FOR DARK MAGA ($DMAGA)
                </span>
                <button className="copy-button" onClick={handleCopyClick}>
                  <img src={copyIcon} alt="Copy" />
                </button>
                {copied && <span className="copied-text">Copied!</span>}
              </p>
              <p className="step-4">
                <span className="step">STEP 4: </span>
                <span className="step-content">
                  PURCHASE FROM YOUR CONNECTED WALLET
                </span>
              </p>
              <a
                className="guide-buy-button"
                href="https://jup.ag/swap/SOL-DMAGA"
                rel="noreferrer"
                target="_blank"
              >
                BUY $DMAGA ON JUPITER
              </a>
            </div>
          )}
        </div>

        <div className="guide-right">
          {/* Show the video and phone only if the selected button is MOONSHOT */}
          {selectedButton === "MOONSHOT" ? (
            <>
              {/* Desktop Phone Container */}
              <div className="phone-container">
                <video
                  className="phone-video"
                  src={phoneVideo1}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
                <img src={iphone} alt="iPhone Frame" className="iphone-frame" />
              </div>

              {/* Mobile Phone Container */}
              <div className="phone-container-mobile">
                <div className="phone-screen">
                  <video
                    className="phone-video-mobile"
                    src={phoneVideo1}
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </div>
                <img
                  src={iphone}
                  alt="iPhone Frame Mobile"
                  className="iphone-frame-mobile"
                />
              </div>
            </>
          ) : (
            // Display the RAYDIUM or JUPYTER images based on the selected button
            <>
              {selectedButton === "RAYDIUM" && (
                <img
                  src={raydiumGuide}
                  alt="Raydium Guide"
                  className="guide-image"
                />
              )}
              {selectedButton === "JUPITER" && (
                <img
                  src={jupyterGuide}
                  alt="Jupyter Guide"
                  className="guide-image"
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default GuideSection;
