import React, { useEffect, useRef } from 'react';
import './TwitterTimeline.css'; // Add custom styles

const TwitterTimeline: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check if the script is already loaded
    const scriptExists = document.getElementById('twitter-wjs');

    if (!scriptExists) {
      const script = document.createElement('script');
      script.id = 'twitter-wjs';
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        renderTimeline();
      };
    } else {
      renderTimeline();
    }

    function renderTimeline() {
      // @ts-ignore
      if (window.twttr && window.twttr.widgets) {
        // Clean up any previous widgets in the container
        if (containerRef.current) {
          containerRef.current.innerHTML = '';
        }

        // Render the Twitter timeline widget
        // @ts-ignore
        window.twttr.widgets.createTimeline(
          {
            sourceType: 'profile',
            screenName: 'DarkMagaCoin',
          },
          containerRef.current,
          {
            height: 600, // Set the height
            theme: 'dark', // Use dark theme
            borderColor: '#E81C4F', // Customize border color
            width: 1000, // Adjust width
          }
        );
      }
    }
  }, []);

  return (
      <div ref={containerRef} className="timeline-container"/>
  );
};

export default TwitterTimeline;
